<template>
    <div>
        <el-dialog :lock-scroll="false" :close-on-click-modal="false" class="dialog" center title="账号密码登录"
            :visible.sync="dialogFormVisible">

            <el-form :model="form" :rules="rules" ref="ruleForm">
                <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
                    <el-input placeholder="请输入邮箱" @keyup.enter.native="login" v-model="form.email"
                        autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" :label-width="formLabelWidth" prop="password">
                    <el-input placeholder="请输入密码" @keyup.enter.native="login" v-model="form.password" autocomplete="off"
                        show-password></el-input>
                </el-form-item>
            </el-form>
            <el-button type="success" class="loginBtn" @click="login" round>登录</el-button>

            <div class="regitstBtn">
                <a class="regist" @click="handleRegister(1)">账号注册</a>
            </div>
            <div class="tips">
                <p>如果网站加载速度比较慢的话，建议使用移动运营商或者开启代理访问</p>
            </div>
        </el-dialog>


        <!-- 邮箱注册 -->
        <el-dialog :lock-scroll="false" class="dialog" :close-on-click-modal="false" title="邮箱注册" center
            :visible.sync="emailRegistFlag">
            <el-form :model="form" :rules="rules" ref="ruleForm" label-position="left">
                <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
                    <el-input class="input" placeholder="请输入邮箱" v-model="form.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="昵称" :label-width="formLabelWidth" prop="nickname">
                    <el-input class="input" placeholder="请输入昵称" v-model="form.nickname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" :label-width="formLabelWidth" prop="password">
                    <el-input class="input" placeholder="请输入密码" v-model="form.password" autocomplete="off"
                        show-password></el-input>
                </el-form-item>
                <el-form-item label="验证码" :label-width="formLabelWidth" prop="code">
                    <div style="display: flex;">
                        <el-input class="input" placeholder="请输入验证码" v-model="form.code" autocomplete="off"></el-input>
                        <a v-if="showSendBtnFlag" class="send" @click="handleSendEmailCode">发送</a>
                        <a v-else class="send">{{ countdown }}s</a>
                    </div>
                </el-form-item>
            </el-form>

            <el-button type="danger" class="loginBtn" @click="register" round>注册</el-button>
        </el-dialog>





    </div>
</template>

<script>
import { emailLogin, openAuthUrl, sendEmailCode, emailRegister } from "@/api";
import { setUrl, setToken } from '@/utils/cookieUtil'

export default {
    data: function () {
        return {
            form: {
                email: null,
                password: null,
            },
            code: null,
            timer: null,
            wechatLoginFlag: false,
            emailRegistFlag: false,
            showSendBtnFlag: true,
            formLabelWidth: '80px',
            wechatLoginCode: null,
            countdown: 60, // 倒计时初始值为 60 秒
            rules: {
                email: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ]
            }
        };
    },

    computed: {
        dialogFormVisible: {
            set(value) {
                this.$store.state.loginFlag = value;
            },
            get() {
                return this.$store.state.loginFlag;
            }
        },
    },
    methods: {
        register() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    emailRegister(this.form)
                        .then(res => {
                            this.$toast.success('注册成功');
                            this.$store.state.loginFlag = true;
                            this.emailRegistFlag = false;
                        })
                        .catch(error => {
                            this.$message.error(error.message);
                            // 在这里处理异常，可以进行相应的提示或其他操作
                        });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        handleSendEmailCode() {
            if (this.form.email == null || this.form.email == '') {
                this.$toast.error('请输入邮箱');
                return;
            }

            sendEmailCode(this.form.email)
                .then(res => {
                    this.timer = setInterval(() => {
                        if (this.countdown > 0) {
                            this.showSendBtnFlag = false;
                            this.countdown--;
                        } else {
                            this.showSendBtnFlag = true;
                            clearInterval(this.timer);
                            this.timer = null;
                            this.countdown = 60;
                        }
                    }, 1000);

                    this.$toast.success('验证码发送成功');
                })
                .catch(error => {
                    // Handle the exception here
                    console.error('Error sending email code:', error);
                    this.$toast.error('验证码发送失败，请重试'); // Display an error toast or handle it appropriately
                });
        },

        handleRegister(type) {
            this.form = {}
            this.$store.state.loginFlag = false;
            if (type == 1) {
                this.emailRegistFlag = true
            }
        },
        close() {
            clearInterval(this.timer);
            this.$store.state.loginFlag = false;
            this.wechatLoginFlag = false
        },
        bacKLogin() {
            clearInterval(this.timer);
            this.form = {}
            this.$store.state.loginFlag = true
            this.wechatLoginFlag = false
            this.emailRegistFlag = false
        },
        isShow(type) {
            return this.$store.state.webSiteInfo.loginTypeList.indexOf(type) != -1
        },
        //Enter事件
        handkeyEnter(event) {
            if (event.keyCode == 13) {
                this.login()
            }
        },
        login() {
  this.$refs['ruleForm'].validate((valid) => {
    if (valid) {
      // 发送登录请求
      emailLogin(this.form)
        .then(res => {
          setToken(res.data.token);
          this.$store.commit("setUserInfo", res.data);
          this.close();
          location.reload();
        })
        .catch(error => {          
          // 在这里处理异常，可以进行相应的提示或其他操作
          this.$message.error(error.message);
        });
    } else {
      console.error('Error in form submission!');
      return false;
    }
  });
},



        openAuthLogin(source) {
            //保留当前路径
            this.settingUrl()
            openAuthUrl(source).then(res => {
                window.open(res.data, "_self");
            });
        },
        settingUrl() {
            if (this.$route.path == "/articleInfo") {
                setUrl("articleId=" + this.$route.query.articleId)
            } else {
                setUrl(this.$route.path)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
    background-image: url(https://smokinggirl.us/img/beijing.jpg) !important;
    background-size: cover; /* 将背景图等比例缩放，保持图像完全覆盖元素 */
}

a {
    cursor: pointer;
}

.dialog {

    @media screen and (min-width: 1119px) {
        /deep/ .el-dialog {
            width: 25%;
            border-radius: 10px;
        }
    }



    .wxImg {
        width: 50%;
        display: block;
        margin: 0 auto;
    }


    .loginBtn {
        margin-top: 30px;
        text-align: center;
        display: block;
        margin: 0 auto;
        width: 50%;
    }

    .regitstBtn {

        .regist{
            color: green;
            margin-top: 30px;
        }
    }

    /deep/ .input input {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }

    .tips{
        margin-top: 30px;
        color: red;
        font-size: 14px;
    }

    .send {
        width: 18%;
        color: var(--text-color);
        font-size: 14px;
        border: none;
        background-color: var(--background-color);

        &:hover {
            color: var(--theme-color)
        }
    }

    .goLoginBtn {
        margin-top: 20px;

        a {
            color: red;
        }
    }

    .social-login-title {
        margin-top: 1.5rem;
        color: #b5b5b5;
        font-size: 0.75rem;
        text-align: center;

        &::before {
            content: "";
            display: inline-block;
            background-color: #d8d8d8;
            width: 60px;
            height: 1px;
            margin: 0 12px;
            vertical-align: middle;
        }

        &::after {
            content: "";
            display: inline-block;
            background-color: #d8d8d8;
            width: 60px;
            height: 1px;
            margin: 0 12px;
            vertical-align: middle;
        }
    }

    .social-login-wrapper {
        margin-top: 1rem;
        font-size: 2rem;
        text-align: center;

        a {
            text-decoration: none;
            margin-left: 20px;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }
}
</style>
