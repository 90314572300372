import request from '@/utils/request'

export function fetchArticleList(params) {
    return request({
        url: '/article/',
        method: 'get',
        params: params
    })
}
export function getArticleByUserId(params) {
    return request({
        url: '/article/selectArticleByUserId',
        method: 'get',
        params: params
    })
}
export function deleteMyArticle(id) {
    return request({
        url: '/article/',
        method: 'delete',
        params: {
            id: id
        }
    })
}
export function getMyArticleInfo(id) {
    return request({
        url: '/article/selectMyArticleInfo',
        method: 'get',
        params: {
            id: id
        }
    })
}
export function readMarkdownFile(data) {
    return request({
        url: '/article/readMarkdownFile',
        method: 'post',
        data
    })
}
export function insertArticle(data) {
    return request({
        url: '/article/',
        method: 'post',
        data
    })
}
export function updateArticle(data) {
    return request({
        url: '/article/',
        method: 'put',
        data
    })
}
export function searchArticle(params) {
    return request({
        url: '/article/search',
        method: 'get',
        params: params
    })
}

export function articleInfo(id) {
    return request({
        url: '/article/info',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function fetchTagList() {
    return request({
        url: '/v1/tag/',
        method: 'get',

    })
}

export function getWebSiteInfo() {
    return request({
        url: '/webSiteInfo',
        method: 'get',
    })
}

export function checkCode(code) {
    return request({
        url: '/article/checkCode',
        method: 'get',
        params: {
            code: code
        }
    })
}
export function articleLike(id) {
    return request({
        url: '/article/like',
        method: 'get',
        params: {
            articleId: id
        }
    })
}


export function featchHomeData() {
    return request({
        url: '/',
        method: 'get',
    })
}

export function emailLogin(data) {
    return request({
        url: '/oauth/emailLogin',
        method: 'post',
        data
    })
}
export function logout() {
    return request({
        url: '/logout',
        method: 'get'
    })
}
export function openAuthUrl(source) {
    return request({
        url: '/oauth/render/' + source,
        method: 'get'
    })
}
export function sendEmailCode(email) {
    return request({
        url: '/oauth/sendEmailCode',
        method: 'get',
        params: {
            email: email
        }
    })
}
export function emailRegister(data) {
    return request({
        url: '/oauth/emailRegister',
        method: 'post',
        data
    })
}
export function updateUserInfo(data) {
    return request({
        url: '/user/',
        method: 'put',
        data
    })
}
export function getUserInfo(userId) {
    return request({
        url: '/user/info',
        method: 'get',
        params: {
            userId: userId
        }
    })
}
export function selectUserInfoByToken(token) {
    return request({
        url: '/user/selectUserInfoByToken',
        method: 'get',
        params: {
            token: token
        }
    })
}
export function upload(data) {
    return request({
        url: '/file/upload',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/articles-data' },
        data
    })
}
export function featchCategory() {
    return request({
        url: '/category/',
        method: 'get'
    })
}

export function addFeedback(data) {
    return request({
        url: '/feedback/',
        method: 'post',
        data
    })
}
export function getCollect() {
    return request({
        url: '/collect/',
        method: 'get'
    })
}
export function collect(id) {
    return request({
        url: '/collect/collect',
        method: 'get',
        params: {
            articleId: id
        }
    })
}
export function cancelCollect(id) {
    return request({
        url: '/collect/',
        method: 'delete',
        params: {
            articleId: id
        }
    })
}

export function followedUser(userId) {
    return request({
        url: '/followed/insertFollowed',
        method: 'post',
        params: {
            userId: userId
        }
    })
}
export function deleteFollowedUser(userId) {
    return request({
        url: '/followed/deleteFollowed',
        method: 'delete',
        params: {
            userId: userId
        }
    })
}

export function bugArticle(id) {
    return request({
        url: '/user/bugArticle',
        method: 'get',
        params: {
            id: id
        }
    })
}

